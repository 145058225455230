<template>
  <div class="flex flex-col mb-10">

    <div style="margin-left: -10px; padding:30px; border: 1px solid #8080807a; border-radius: 25px;; border-radius: 5px;">
    
     <div class="md-layout md-gutter">
       <div class="md-layout-item md-size-100 md-xsmall-size-100 md-small-size-100">
        <Content textColor='black' class="my-3 text-uppercase"  text="Number Of PTE Practice" />
       </div>
       <div class="md-layout-item md-size-30 md-xsmall-size-30 md-small-size-30">
         <Content text-victoria class="my-3 text-uppercase"  text="Reading Section" />
         <ul>
         <li v-for="(item, index) in READING" :key="'R-'+index">
           <label :class="{'active': item.id == activeIndex}"  @click="getQuestionbySection(item.id)" class="text-capitalize ml-2 text-base text-gray-600">{{ item.name }}</label>
        </li>
        </ul>
        <Content text-victoria class="my-3 text-uppercase"  text="Writing Section" />
        <ul>
         <li v-for="(item, index) in WRITING" :key="'W-'+index">
           <label :class="{'active': item.id == activeIndex}" @click="getQuestionbySection(item.id)" class="text-capitalize ml-2 text-base text-gray-600">{{ item.name }}</label>
        </li>
        </ul>
        <Content text-victoria class="my-3 text-uppercase"  text="SPEAKING Section" />
        <ul>
          <li v-for="(item, index) in SPEAKING" :key="'S-'+index">
            <label :class="{'active': item.id == activeIndex}" @click="getQuestionbySection(item.id)" class="text-capitalize ml-2 text-base text-gray-600">{{ item.name }}</label>
          </li>
        </ul>
        <Content text-victoria class="my-3 text-uppercase"  text="SPEAKING Section" />
        <ul>
         <li v-for="(item, index) in LISTENING" :key="'L-'+index">
           <label :class="{'active': item.id == activeIndex}" @click="getQuestionbySection(item.id)" class="text-capitalize ml-2 text-base text-gray-600">{{ item.name }}</label>
        </li>
        </ul>
       </div>
       <div class="md-layout-item md-size-70 md-xsmall-size-70 md-small-size-70" style="padding:30px; border: 1px solid #8080807a; border-radius: 25px;; border-radius: 5px;">
         <div class="md-layout md-gutter">
         <div class="md-layout-item md-size-30 md-xsmall-size-30 md-small-size-30">
          <h4 class=""> {{this.pteQuestions.length>0?this.pteQuestions[0].item:''}} 
            <!-- <span class="rounded bg-victoria text-white font-bold px-2 ">
            {{this.form.pte_practice_questions.length}}
            </span> -->
            </h4>
        </div>
        <div class="md-layout-item md-size-70 md-xsmall-size-70 md-small-size-70">
          <div class="mb-4 flex justify-end flex-wrap justify-xs-center">
            <md-checkbox @change="selectAllQuestionId($event)" v-model="form.pte_practice_all_access" >All Access</md-checkbox>
            <SearchBox
              placeholder="Search"
              class="w-auto p-2 m-1"
              v-model="keyword"
              @submit="submitSearchKeyword"
              />
          </div>
        </div>
         </div>
        <md-divider></md-divider>
         <Table :items="pteQuestions" hover-action>

              <template slot="row" slot-scope="{ item }">
                <md-table-cell md-sort-by="id" @click.native="selectQuestionId(item.id)" md-label="SL" md-numeric>{{ item.sl }}</md-table-cell>
                <md-table-cell md-sort-by="qid" @click.native="selectQuestionId(item.id)" md-label="QID" class="text-victoria">{{ item.q_id }}</md-table-cell>
                <md-table-cell md-sort-by="index" @click.native="selectQuestionId(item.id)" md-label="INDEX">{{ item.index }} 
                  
                  <span v-if="form.pte_practice_questions.includes(item.id)" class="absolute" style="right:0">
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
                      <path :fill="'#00df05'" d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M17.7,9.7l-7,7C10.5,16.9,10.3,17,10,17s-0.5-0.1-0.7-0.3l-3-3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l2.3,2.3l6.3-6.3c0.4-0.4,1-0.4,1.4,0S18.1,9.3,17.7,9.7z"  />
                    </svg>
                  </span>
                </md-table-cell>
              </template>
          </Table>
       </div>
     </div>     
    </div>   

    <div class="flex justify-end mt-4">
      <!-- <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button> -->
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Next"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Table,
  SearchBox
} from "@/components";

import { mapGetters,mapActions} from 'vuex';
import Content from "@/components/atom/Content";
import { query } from "@/utils/queryString";

export default {
  components: {
    Button,
    Content,
    Table,
    SearchBox
  },

  data() {
    return {
      loading: false,
      activeColor:String,
      activeIndex: null,
  
      SPEAKING: '',
      WRITING: '',
      READING: '',
      LISTENING: '',
      keyword: '',
      selectedKeyword: '', 
      questions:[],
      form: { 
        pte_practice_all_access: false,
        pte_practice_questions:[],
        pte_practice:'',

      } 
      
    }
  },
  computed:{
   ...mapGetters({
          getQuestionTypes: "question/getQuestionTypes",
          pteQuestions: "question/getSectionWiseQuestion",
      }),
  },
   watch: {
    keyword(oldvalue, newValue) {
        query.set('search', oldvalue)
        // For reseting search result
        if(oldvalue.length < newValue.length && oldvalue.length == 0) {
          this.actSectionWiseQuestion(`?${query.get()}`);
        }
        // For submitting search api after 3 cherecter
        if(oldvalue.length >= 3) {
          this.actSectionWiseQuestion(`?${query.get()}`);
        }
    }
  },
  methods: {
   
      ...mapActions({
        actGetQuestionTypes: "question/actGetQuestionTypes",
        actSectionWiseQuestion: "question/actSectionWiseQuestion",
    }),

    selectAllQuestionId(id){
     
      if(id){
        this.pteQuestions.map((item) => {
          this.form.pte_practice_questions.push(item.id);
          this.questions.push(item.id);
        });
      }else{
        for (let i = 0; i < this.form.pte_practice_questions.length; i++) {
          this.questions.indexOf(this.form.pte_practice_questions[i]) === -1 ?  '': this.questions.splice(this.questions.indexOf(this.form.pte_practice_questions[i]),1);
        }
        this.form.pte_practice_questions = [];
      }
    },
    selectQuestionId(id){
  
      this.form.pte_practice_questions.indexOf(id) === -1 ? this.form.pte_practice_questions.push(id): this.form.pte_practice_questions.splice(this.form.pte_practice_questions.indexOf(id),1);
      this.questions.indexOf(id) === -1 ? this.questions.push(id): this.questions.splice(this.questions.indexOf(id),1);
    },

    formClear(){
      this.form = {
        pte_practice_questions:[],
        pte_practice:'',
      }
    },

    submitSearchKeyword() {
      this.actSectionWiseQuestion(`?${query.get()}`);  
    },
    getQuestionbySection(id){
      
      this.activeIndex = id;
      query.set('question_type_id', id)
      this.actSectionWiseQuestion(`?${query.get()}`);  
    },   
    submit() {
      
      let formData = this.getLocal('package-create-info');
      this.form.pte_practice = this.form.pte_practice_questions.length;
    
      if(this.questions.length > 0){
        formData.questions = [ ...this.questions ];
        formData.package_detail = {
          ...formData.package_detail,
          ...this.form
          
        };
      }

      
      this.setLocal('package-create-info', formData);

      query.remove('question_type_id');
      query.remove('search');

      this.$emit("menu", this.$route.name);
  
      this.getPropertiesRoute();
    },

    getPropertiesRoute(){
      let packageInfo = this.getLocal("package-create-info"); 
      if(packageInfo && packageInfo.package_detail){

        if(packageInfo.package_detail.is_practice_test){
          this.$router.push({ name: 'packageCreation.properties.practiceTest' });
          return;
        }
        if(packageInfo.package_detail.is_mock_test){
          this.$router.push({ name: 'packageCreation.properties.mockTest' });
          return;
        }
        if(packageInfo.package_detail.is_quiz){
          this.$router.push({ name: 'packageCreation.properties.quiz' });
          return;
        }
        if(packageInfo.package_detail.is_materials){
          this.$router.push({ name: 'packageCreation.properties.materials' });
          return;
        }
        if(packageInfo.package_detail.is_live_class){
          this.$router.push({ name: 'packageCreation.properties.liveClass' });
          return;
        }
        if(packageInfo.package_detail.is_webinars){
          this.$router.push({ name: 'packageCreation.properties.webinars' });
          return;
        }
        if(packageInfo.package_detail.is_one_to_one_appointment){
          this.$router.push({ name: 'packageCreation.properties.OneToOneAppointment' });
          return;
        }
        this.$router.push({ name: 'packageCreation.durationAndPrice' });
      }
    },

    clearAll() {
      this.$emit("clear-all", "");
    }
  },

  created() {

    this.actGetQuestionTypes(`?section=SPEAKING`).then(() => {
        this.SPEAKING = this.getQuestionTypes;
    });
    this.actGetQuestionTypes(`?section=WRITING`).then(() => {
        this.WRITING = this.getQuestionTypes;
    });
    this.actGetQuestionTypes(`?section=READING`).then(() => {
        this.READING = this.getQuestionTypes;
    });
    this.actGetQuestionTypes(`?section=LISTENING`).then(() => {
        this.LISTENING = this.getQuestionTypes;
    });

    query.remove('type');
    query.remove('section');
    query.remove('question_type_id');
    query.remove('search');

    let existData = this.getLocal('package-create-info');
    if (existData && existData.package_detail && existData.package_detail.pte_practice_questions) this.form = existData.package_detail;
    if (existData && existData.questions) this.questions = existData.questions;
  }
}
</script>
<style scoped>
  li label{
      cursor: pointer;
  }
  .active {
    color: black !important;
    font-weight: bold;
  }
</style>